<template>
  <div class="error _noprojects">
    <div class="error__container _noprojects">
      <icon
          name="empty-state"
          width="124px"
          height="160px"
      />

      <div class="noprojects__title">У вас нет действующих проектов в Dataforce.</div>
      <div class="noprojects__subtitle">
        Напишите нам на <a class="noprojects__link" href="mailto:help@dataforce.ru">help@dataforce.ru</a> и мы поможем вам создать проект. <br>
        Больше узнать о Dataforce можете по ссылкам ниже.
      </div>

      <slot name="links">
        <ul class="noprojects__list">
          <li>
            <router-link
                :to="{ name: ROUTE_NAME.LANDING }" class="noprojects__link"
            >
              Наш сайт
            </router-link>
          </li>
          <li>
            <a
                href="https://api.dataforce.io/dataforce_prez.pdf"
                class="noprojects__link"
                target="_blank"
            >
              Презентация
            </a>
          </li>
          <li>
            <router-link
                :to="{ name: ROUTE_NAME.DEMO }"
                class="noprojects__link"
            >
              Демо возможностей платформы
            </router-link>
          </li>
        </ul>
      </slot>
    </div>
  </div>
</template>

<script>
import ROUTE_NAME from '@/router/routeName'

export default {
  data () {
    return {
      ROUTE_NAME
    }
  }
}
</script>

<style lang="scss" src="./errors.scss" scoped></style>
